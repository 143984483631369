import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { Theme } from 'utils/theme';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AppCenterContextProvider } from 'AppContext';
import 'utils/global.css';
import { Toaster } from 'react-hot-toast';
import Cookies from 'components/Cookies';
import Login from 'admin/pages/Login';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';
import SingleVipPreview from 'admin/pages/SingleVipPreview';
import VipBookletPreview from 'admin/components/VipBookletPreview';
import VipList from 'admin/pages/VipList';
import EditVip from 'admin/pages/EditVip';
import AdminVipList from 'admin/pages/adminPages/AdminVipList';
import AdminEditVip from 'admin/pages/adminPages/AdminEditVip';
import AdminVipPreview from 'admin/pages/adminPages/AdminVipPreview';
import NotFound from 'admin/pages/notFound';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  // VIP routes with specific patterns
  // {
  //   path: '/:code([a-zA-Z0-9]{14})',
  //   element: <VipList />
  // },
   {
    path: '/:code',
    element: <VipList />
  },
  {
    path: '/:code/broszura/wszyscy',
    element: <VipBookletPreview />
  },
  {
    path: '/:code/podglad/:id',
    element: <SingleVipPreview />
  },
  {
    path: '/:code/edytuj',
    element: <EditVip />
  },
  // {
  //   path: '/:code([a-zA-Z0-9]{14})/broszura/wszyscy',
  //   element: <VipBookletPreview />
  // },
  // {
  //   path: '/:code([a-zA-Z0-9]{14})/podglad/:id',
  //   element: <SingleVipPreview />
  // },
  // {
  //   path: '/:code([a-zA-Z0-9]{14})/edytuj',
  //   element: <EditVip />
  // },
  
  // Admin routes
  {
    path: '/admin',
    element: <Login />
  },
  {
    path: '/admin/menu',
    element: <AdminVipList />
  },
  {
    path: '/admin/menu/podglad/:id',
    element: <AdminVipPreview />
  },
  {
    path: '/admin/menu/edytuj/:id',
    element: <AdminEditVip />
  },
  {
    path: '/admin/menu/broszura/wszyscy',
    element: <VipBookletPreview />
  },

  // Invalid :code catcher 404
  {
    path: '*',
    element: <NotFound />
  }
]);

root.render(
  <ThemeProvider theme={Theme}>
    <Toaster />
    <AppCenterContextProvider>
      <RouterProvider router={router} />
      <div id='modal-portal' />
      <Cookies />
    </AppCenterContextProvider>
  </ThemeProvider>
);
