import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
// @ts-ignore
import logo from 'assets/prb-logo.png'
import { Link, useParams } from 'react-router-dom'
import { mobileCss } from 'utils/theme'
import { Drawer } from '@mui/material'
import NamesNavigation from 'admin/components/regular/NamesNavigation.'
import { VipTableRowTypes } from 'utils/vipTableRowTypes'
import { supabase } from 'utils/supabase'

const VipMenu = () => {
  
  const { code } = useParams()
  
  const [open, setOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [vips, setVips] = useState<VipTableRowTypes[]>([])
  
  const getVips = async () => {
    try {
      const response = await supabase
        .from('vip_links')
        .select('*')
      
      const data = response.data as VipTableRowTypes[]
      
      setVips(data)
      
    } catch (err) {
      console.error(err)
    }
  }
  
  useEffect(() => {
    getVips()
  }, [])
  
  const toggleOpen = () => {
    setOpen((prev) => !prev)
  }
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1036)
    }
    
    window.addEventListener('resize', handleResize)
    handleResize()
    
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  
  return (
    <Wrapper>
      {isMobile ? (
        <MobileNav>
          <Hamburger open={open} onClick={toggleOpen}>
            <Bar open={open} />
          </Hamburger>
          <Logo src={logo} alt='who is who' />
          <Drawer anchor='left' open={open} onClose={toggleOpen}>
            <DrawerContent>
              <Hamburger open={open} onClick={toggleOpen}>
                <Bar open={open} />
              </Hamburger>
              <LinksWrapper>
                <StyledLink to={`/${code}`}>Strona główna</StyledLink>
                {code && <StyledLink to={`/${code}/edytuj`}>Edytuj swój profil</StyledLink>}
                <NamesNavigation vips={vips} onLinkClick={toggleOpen}/>
              </LinksWrapper>
            </DrawerContent>
          </Drawer>
        </MobileNav>
      ) : (
        <DesktopNav>
          <Logo src={logo} alt='who is who' />
          <LinksWrapper>
            <StyledLink to={`/${code}`}>Strona główna</StyledLink>
            {code && <StyledLink to={`/${code}/edytuj`}>Edytuj swój profil</StyledLink>}
            <NamesNavigation vips={vips} onLinkClick={toggleOpen}/>
          </LinksWrapper>
        </DesktopNav>
      )}
    </Wrapper>
  )
}

export default VipMenu

const Wrapper = styled.div`
  width: auto;
  background: #f2f2f2;
  padding: 15px;
  position: sticky;
  top: 0;
  z-index: 1000;

  ${mobileCss(`
  width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  `)};
`
const DesktopNav = styled.div`
  max-width: 180px;
`
const MobileNav = styled.div`
`

const Logo = styled.img`
  width: 150px;
  text-align: center;
  margin-bottom: 50px;

  @media (max-width: 1036px) {
    display: none;
  }
`

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledLink = styled(Link)`
  padding: 12px;
  border-radius: 8px;
  transition: all .3s;
  text-decoration: none;
  color: #444;
  font-weight: 400;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
  }
`
const Hamburger = styled.div<{ open: boolean }>`
  display: none;
  flex-direction: column;
  gap: 5px;
  width: 30px;
  cursor: pointer;
  z-index: 200;

  ${mobileCss(`
    display: flex;
  `)};

  &::before,
  &::after,
  div {
    content: '';
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.secondary};
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  ${({ open }) =>
          open &&
          css`
            &::before {
              transform: rotate(45deg) translateY(10px);
            }

            &::after {
              transform: rotate(-45deg) translateY(-10px);
            }

            div {
              opacity: 0;
            }
          `}
`
const Bar = styled.div<{ open: boolean }>`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.secondary};
  transition: opacity 0.3s ease;
`
const DrawerContent = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 1036px) {
    display: none;
  }
`
