export const vipFields = [
  {
    label: 'Imię i nazwisko',
    key: 'vipFullName',
    type: 'input'
  },
  {
    label: 'Nazwisko do celu sortowania',
    key: 'vipSortingName',
    type: 'input'
  },
  {
    label: 'Stanowisko',
    key: 'vipTitle',
    type: 'input'
  },
  // {
  //   label: 'Opis skrócony',
  //   key: 'vipShortDescription',
  //   type: 'wysiwyg'
  // },
  {
    label: 'Branża/branże',
    key: 'vipDescriptionP1',
    type: 'wysiwyg'
  },
  {
    label: 'Bio',
    key: 'vipDescriptionP2',
    type: 'wysiwyg'
  },
  {
    label: 'Moje firmy/Opis biznesu',
    key: 'vipDescriptionP3',
    type: 'wysiwyg'
  },
  {
    label: 'Moja działalność społeczna',
    key: 'vipDescriptionP4',
    type: 'wysiwyg'
  },
  {
    label: 'Partnerzy biznesowi i sukcesorzy',
    key: 'vipDescriptionP5',
    type: 'wysiwyg'
  },
  {
    label: 'Zainteresowania/hobby',
    key: 'vipDescriptionP6',
    type: 'wysiwyg'
  },
  {
    label: 'Prezentacja 1 - nazwa',
    key: 'vipPresentationTitle1',
    type: 'input'
  },
  {
    label: 'Prezentacja 1 - link',
    key: 'vipPresentationLink1',
    type: 'input'
  },
  {
    label: 'Prezentacja 2 - nazwa',
    key: 'vipPresentationTitle2',
    type: 'input'
  },
  {
    label: 'Prezentacja 2 - link',
    key: 'vipPresentationLink2',
    type: 'input'
  },
  {
    label: 'Prezentacja 3 -nazwa',
    key: 'vipPresentationTitle3',
    type: 'input'
  },
  {
    label: 'Prezentacja 3 -link',
    key: 'vipPresentationLink3',
    type: 'input'
  },
  // {
  //   label: 'Prezentacja 4',
  //   key: 'vipPresentationTitle4',
  //   type: 'input'
  // },
  // {
  //   label: 'Prezentacja 4 - link',
  //   key: 'vipPresentationLink4',
  //   type: 'input'
  // },
  // {
  //   label: 'Prezentacja 5',
  //   key: 'vipPresentationTitle5',
  //   type: 'input'
  // },
  // {
  //   label: 'Prezentacja 5 - link',
  //   key: 'vipPresentationLink5',
  //   type: 'input'
  // },
  // {
  //   label: 'Prezentacja 6',
  //   key: 'vipPresentationTitle6',
  //   type: 'input'
  // },
  // {
  //   label: 'Prezentacja 6 - link',
  //   key: 'vipPresentationLink6',
  //   type: 'input'
  // },
  // {
  //   label: 'Prezentacja 7',
  //   key: 'vipPresentationTitle7',
  //   type: 'input'
  // },
  // {
  //   label: 'Prezentacja 7 - link',
  //   key: 'vipPresentationLink7',
  //   type: 'input'
  // },
  // {
  //   label: 'Prezentacja 8',
  //   key: 'vipPresentationTitle8',
  //   type: 'input'
  // },
  // {
  //   label: 'Prezentacja 8 - link',
  //   key: 'vipPresentationLink8',
  //   type: 'input'
  // }, {
  //   label: 'Prezentacja 9',
  //   key: 'vipPresentationTitle9',
  //   type: 'input'
  // },
  // {
  //   label: 'Prezentacja 9 - link',
  //   key: 'vipPresentationLink9',
  //   type: 'input'
  // },
  // {
  //   label: 'Prezentacja 10',
  //   key: 'vipPresentationTitle10',
  //   type: 'input'
  // },
  // {
  //   label: 'Prezentacja 10 - link',
  //   key: 'vipPresentationLink10',
  //   type: 'input'
  // },
  {
    label: 'Strona www',
    key: 'vipWebsite',
    type: 'input'
  },
  {
    label: 'Profil LinkedIn',
    key: 'vipLinkedIn',
    type: 'input'
  },
  {
    label: 'Email do kontaktu',
    key: 'vipEmail',
    type: 'input'
  },
  {
    label: 'Telefon',
    key: 'vipMobile',
    type: 'input'
  }
]

export interface FormFieldsTypes {
  label: string
  key: string
}

export const getFormInitialValues = (fields: FormFieldsTypes[]) => {
  let v = {}
  
  const getValue = (type: string) => {
    switch (type) {
      case 'input':
      case 'image':
        return ''
      default:
        return ''
    }
  }
  fields.forEach(field => {
    // @ts-ignore
    v[field.key] = getValue(field.type)
  })
  
  return v
}

// export const findTableSize = (data: TableValues) => {
//   let maxColumn = 0
//   let maxRow = 0
//
//   for (const key in data) {
//     // @ts-ignore
//     if (data.hasOwnProperty(key) && data[key] !== '') {
//       const [, row, column] = key.match(/td(\d)(\d)/) || []
//
//       if (column && row) {
//         maxColumn = Math.max(maxColumn, parseInt(column, 10))
//         maxRow = Math.max(maxRow, parseInt(row, 10))
//       }
//     }
//   }
//
//   return { columns: maxColumn, rows: maxRow };
// }
